import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CreateIcon from "@mui/icons-material/Create";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { motion } from "framer-motion";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Landing = () => {
  const letterAnimation = {
    whileHover: { scale: 1.2 },
    transition: { type: "spring", stiffness: 300, damping: 15 },
  };

  // Define unique bounce-in animations for each word
  const wordAnimations = {
    creative: {
      initial: { opacity: 0, scale: 0.5 },
      animate: { opacity: 1, scale: 1 },
      transition: { type: "spring", stiffness: 170, damping: 12 },
    },
    s: {
      initial: { opacity: 0, y: 100 },
      animate: { opacity: 1, y: 0 },
      transition: { type: "spring", stiffness: 150, damping: 14 },
    },
    software: {
      initial: { opacity: 0, x: -100 },
      animate: { opacity: 1, x: 0 },
      transition: { type: "spring", stiffness: 130, damping: 16 },
    },
    devel: {
      initial: { opacity: 0, scale: 0.8 },
      animate: { opacity: 1, scale: 1 },
      transition: { type: "spring", stiffness: 110, damping: 18 },
    },
    per: {
      initial: { opacity: 0, x: 100 },
      animate: { opacity: 1, x: 0 },
      transition: { type: "spring", stiffness: 90, damping: 20 },
    },
  };

  const Word = ({ text, animation, reduceSpace }: any) => {
    const theme = useTheme();
    return (
      <motion.div
        initial={animation.initial}
        animate={animation.animate}
        transition={animation.transition}
        style={{ display: "inline-flex", zIndex: 2 }}
      >
        <Typography
          sx={{
            fontSize: "110px",
            fontFamily: "inter",
            letterSpacing: "12px",
            lineHeight: 1.2,
            fontWeight: 900,
            display: "flex",
            flexWrap: "nowrap",
            [theme.breakpoints.down(1400)]: {
              fontSize: "90px",
            },
            [theme.breakpoints.down(1200)]: {
              fontSize: "80px",
            },

            [theme.breakpoints.down(700)]: {
              letterSpacing: reduceSpace ? "0px" : "12px",
              fontSize: "70px",
            },
            [theme.breakpoints.down(620)]: {
              fontSize: "70px",
            },
            [theme.breakpoints.down(570)]: {
              fontSize: "50px",
            },
            [theme.breakpoints.down(470)]: {
              fontSize: "35px",
            },
          }}
        >
          {text.split("").map((letter: string, index: number) => (
            <motion.span
              key={index}
              whileHover={letterAnimation.whileHover}
              transition={letterAnimation.transition}
              style={{ display: "inline-block" }}
            >
              {letter}
            </motion.span>
          ))}
        </Typography>
      </motion.div>
    );
  };
  const animation = {
    initial: { x: "100vw", opacity: 0 },
    animate: { x: 0, opacity: 1 },
    transition: {
      type: "spring",
      stiffness: 150,
      damping: 20,
      duration: 2,
    },
  };

  const scrollToWork = () => {
    const workSection = document.getElementById("work");
    if (workSection) {
      workSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1000));
  return (
    <Box
      id="landing"
      sx={{
        height: isMobile ? "50rem" : "48rem",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "5vh",
      }}
    >
      <Box
        sx={{
          height: "40rem",
          overflow: "hidden",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          maxWidth: "1500px",
          position: "relative",
          [theme.breakpoints.down(1085)]: {
            flexDirection: "column",
            padding: "25px",
          },
          [theme.breakpoints.down(800)]: {
            padding: "25px",
          },
          [theme.breakpoints.down(600)]: {
            padding: "25px",
          },

          "&:hover": {
            cursor: "default",
          },
        }}
      >
        <Box
          sx={{
            width: "70%",
            height: "80%",

            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "flex-start",
            [theme.breakpoints.down(1085)]: {
              width: "100%",
              height: "70%",
            },
            [theme.breakpoints.down(800)]: {
              width: "100%",
              height: "60%",
            },
            [theme.breakpoints.down(600)]: {
              width: "95%",
              height: "45%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              height: "100%",
              flexWrap: "nowrap",
            }}
          >
            <Box
              sx={{
                ml: 20,
                flexWrap: "nowrap",
                display: "flex",
                [theme.breakpoints.down(1000)]: {
                  ml: 10,
                },

                [theme.breakpoints.down(750)]: {
                  ml: 0,
                },
              }}
            >
              <Word text="CREATIVE" animation={wordAnimations.creative} />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Word reduceSpace={true} text="S" animation={wordAnimations.s} />
              <a
                href="mailto:louis.cappell@icloud.com"
                style={{ textDecoration: "none", zIndex: 2 }}
              >
                <Box
                  component={motion.div}
                  {...wordAnimations.software}
                  sx={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "100%",
                    background:
                      "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                    display: "flex",
                    alignItems: "center",
                    zIndex: 1000,
                    justifyContent: "center",
                    marginRight: "15px",
                    [theme.breakpoints.down(1000)]: {
                      width: "60px",
                      height: "60px",
                    },
                    [theme.breakpoints.down(750)]: {
                      width: "40px",
                      height: "40px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    },
                    [theme.breakpoints.down(650)]: {
                      width: "35px",
                      height: "35px",
                      marginRight: "5px",
                    },
                    "&:hover": {
                      filter: "blur(1px)",
                      cursor: "pointer",
                    },
                  }}
                >
                  <CreateIcon
                    sx={{
                      width: "40px",
                      zIndex: 2,
                      height: "40px",
                      color: "#030303",
                      [theme.breakpoints.down(1000)]: {
                        width: "30px",
                        height: "30px",
                      },
                      [theme.breakpoints.down(750)]: {
                        width: "20px",
                        height: "20px",
                      },
                    }}
                  />
                </Box>
              </a>

              <Word text="FTWARE" animation={wordAnimations.software} />
            </Box>
            <Box
              sx={{
                ml: 10,
                [theme.breakpoints.down(750)]: {
                  ml: 0,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <Word text="DEVE" animation={wordAnimations.devel} />
                <Word
                  reduceSpace={true}
                  text="L"
                  animation={wordAnimations.devel}
                />
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/louiscappell?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BVMMUvaBgRg2cKz07Yg5vcg%3D%3D"
                  rel="noreferrer"
                  style={{
                    zIndex: 2,
                  }}
                >
                  <Box
                    component={motion.div}
                    {...wordAnimations.devel}
                    sx={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "100%",
                      background:
                        "linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%)",
                      display: "flex",
                      alignItems: "center",
                      zIndex: 2,
                      justifyContent: "center",
                      marginRight: "15px",
                      [theme.breakpoints.down(1000)]: {
                        width: "60px",
                        height: "60px",
                      },
                      [theme.breakpoints.down(700)]: {
                        width: "40px",
                        height: "40px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      },
                      [theme.breakpoints.down(650)]: {
                        width: "35px",
                        height: "35px",
                        marginRight: "5px",
                      },
                      "&:hover": {
                        filter: "blur(1px)",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <LinkedInIcon
                      sx={{
                        width: "40px",
                        height: "40px",
                        zIndex: 2,
                        color: "#030303",
                        [theme.breakpoints.down(1000)]: {
                          width: "30px",
                          height: "30px",
                        },
                        [theme.breakpoints.down(750)]: {
                          width: "20px",
                          height: "20px",
                        },
                      }}
                    />
                  </Box>
                </a>
                <Word text="PER." animation={wordAnimations.per} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          component={motion.div}
          initial={animation.initial}
          animate={animation.animate}
          transition={animation.transition}
          sx={{
            width: "20%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            zIndex: 2,
            position: "relative",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
            [theme.breakpoints.down(1085)]: {
              height: "40%",
              width: "80%",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            },
            [theme.breakpoints.down(800)]: {
              height: "40%",
              width: "90%",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              color: "#030303",
              fontFamily: "inter",
              fontWeight: 800,
              opacity: 0.45,
              zIndex: 2,
            }}
          >
            Passionate about building engaging and beautiful modern web and
            mobile applications.
          </Typography>
        </Box>
      </Box>
      <Box
        component={motion.div}
        animate={{ y: ["0%", "-3%", "0%"] }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "loop",
          repeatDelay: 0,
        }}
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          pb: "13rem",
          flexDirection: "row",
          gap: "5px",
          zIndex: 2,
        }}
      >
        <Box
          onClick={scrollToWork}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              cursor: "pointer",
              opacity: 0.7,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              color: "#030303",
              fontFamily: "inter",
              fontWeight: 800,

              opacity: 0.45,
              zIndex: 2,
            }}
          >
            view more
          </Typography>
          <KeyboardArrowDownIcon
            sx={{
              width: "30px",
              height: "30px",
              color: "#030303",
              fontFamily: "inter",
              fontWeight: 800,

              opacity: 0.45,
              zIndex: 2,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Landing;
