import { Box, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import WorkItem from "../../Components/WorkItem";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";

const Pintly1 = require("../../assets/pintlyImg.jpg");
const node4 = require("../../assets/node4Logo.png");
const logo = require("../../assets/download.jpeg");

const Work = ({ onToggle }: any) => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState<any>({ open: false });

  const handleOpenModal = (values: any) => {
    setModalOpen({ open: true, ...values });
  };
  const isMobile = useMediaQuery(theme.breakpoints.down(1000));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <Box
      id="work"
      sx={{
        display: "flex",
        alignItems: "center",
        maxWidth: "2000px",
        justifyContent: "flex-start",
        overflow: "hidden",
        width: "100%",
        height: "46rem",
        position: "relative",
        paddingBottom: 10,
        flexDirection: "column",
        background:
          "linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, #a1b3d1 100%), linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%)",
        [theme.breakpoints.down(800)]: {
          height: "100%",
        },
      }}
    >
      <Box
        component={motion.div}
        transition={{ ease: "linear", type: "spring" }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          flexDirection: "column",
          gap: "5px",
          zIndex: 5,
        }}
      >
        <Typography
          sx={{
            fontFamily: "inter",
            fontSize: "40px",
            fontWeight: 700,
          }}
        >
          my work.
        </Typography>
        <Box
          component={motion.div}
          animate={{ x: ["0%", "-3%", "0%"] }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 0,
          }}
        >
          <Typography
            sx={{
              fontFamily: "inter",
              fontSize: "18px",
              fontWeight: 600,
              opacity: 0.4,
            }}
          >
            click boxes to expand.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "40px",
          height: "100%",
          paddingTop: "20px",
          paddingBottom: "60px",
          padding: 5,

          [theme.breakpoints.down(800)]: {
            flexDirection: "column",
          },
        }}
      >
        <WorkItem
          onModalOpen={handleOpenModal}
          title="Pintly"
          images={[Pintly1]}
          imageDim={{ width: "450px", height: "100px" }}
          role="Founder/Developer"
          techStack={["TypeScript", "React Native", "Redux"]}
          links={{
            label: "Download the app here!",
            value: `https://linktr.ee/pintly`,
          }}
          date="2024-Present"
          longSummary={`I co-founded and built the entire front end of Pintly. An app that shows pint prices for pubs in London.
          Since launch the app has gained 30k+ users, reaching the top of the UK food and drinks category. 
          `}
        />
        <WorkItem
          onModalOpen={handleOpenModal}
          title="345 Global"
          images={[logo]}
          imageDim={{ width: "450px", height: "100px" }}
          summary={""}
          role="Front End Engineer"
          techStack={["TypeScript", "React", "Redux", "MUI"]}
          links={{
            label: "345 Global Website",
            value: `https://www.345.global/`,
          }}
          date="2021-2024"
          longSummary={`In the two and a half years I spent at 345 Global, my
          role involved building complex features that were vital
          for enhancing the app's user experience, including developing a
          secure login mechanism, an interactive comment system, and
          responsive notifications. I was responsible and held accountable for a large portion of the application's front end. 
          `}
        />
        <WorkItem
          onModalOpen={handleOpenModal}
          title="Node4"
          images={[node4]}
          imageDim={{ width: "450px", height: "100px" }}
          summary={""}
          techStack={["TypeScript", "React", "Redux", "Python"]}
          role="Software Engineer Intern"
          links={{ label: "Node4 website", value: `https://node4.co.uk/` }}
          date="2020-2021"
          longSummary={`A one year internship. An experience that gave me the skill-set and confidence to move onto larger and more complex roles.`}
        />
      </Box>
      <Modal
        open={modalOpen?.open}
        hideBackdrop={false}
        onClose={() => setModalOpen({ open: false })}
        sx={{
          outline: "none",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: isSmallMobile ? "80%" : isMobile ? "80%" : "50%",
            backdropFilter: "blur(20px)",
            background: "rgb(255, 255, 255)",
            padding: 3,
            position: "relative",
            borderRadius: 5,
            outline: "none",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CloseIcon
              onClick={(event) => {
                setModalOpen({ open: false });
              }}
              sx={{
                position: "absolute",
                right: 20,
                top: 20,

                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
            <Typography
              sx={{
                fontSize: 24,
                fontFamily: "inter",
                fontWeight: 600,
              }}
            >
              {modalOpen?.title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: 2,
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontFamily: "inter",
                fontSize: 18,
                fontWeight: 600,
                paddingBottom: 1,
              }}
            >
              Work summary:
            </Typography>
            <Typography
              sx={{
                fontFamily: "inter",
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {modalOpen.longSummary}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: 2,
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontFamily: "inter",
                fontSize: 18,
                fontWeight: 600,
                paddingBottom: 1,
              }}
            >
              Tech stack:
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              {modalOpen?.techStack &&
                modalOpen?.techStack.map((tech: string) => {
                  console.log(tech);
                  return (
                    <Typography
                      sx={{
                        fontFamily: "inter",
                        fontSize: 16,
                        fontWeight: 400,
                        textAlign: "left",
                      }}
                    >
                      - {`${tech}`}
                    </Typography>
                  );
                })}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: 2,
              flexDirection: "column",
            }}
          >
            <a
              target="_blank"
              href={modalOpen?.links?.value}
              style={{
                marginTop: 5,
                textDecoration: "none",
                color: "#030303",
              }}
              rel="noreferrer"
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  pt: "10px",
                  fontWeight: 600,
                  fontFamily: "inter",
                  color: "#030303",
                  "&:hover": {
                    opacity: 0.6,
                  },
                }}
              >
                {modalOpen?.links?.label}
              </Typography>
            </a>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Work;
